import axios from "axios";
import { config } from "../../utils/config";
import { base_url } from "../../utils/baseUrl";
const login = async (user) => {
  const response = await axios.post(`${base_url}auth/login`, user);
  if (response.data) {
    
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const logout = async (user) => {
  localStorage.removeItem("user");
};


const authProvider = {
  login,
  logout
};

export default authProvider;
