import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import productReducer from "./product/productSlice";
import uploadReducer from "./upload/uploadSlice";
import globeBaseCateReducer from "./globeBaseCategory/globeBaseCategorySlice"

export const store = configureStore({
    reducer: {
        auth: authReducer,
        product: productReducer,
        upload: uploadReducer,
        globeCat: globeBaseCateReducer
    },
  });
  