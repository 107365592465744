import axios from "axios";
import { config } from "../../utils/config";
import { base_url } from "../../utils/baseUrl";

const getGlobeCategories = async () => {
  const response = await axios.get(`${base_url}categories/getAllGlobeBaseCat`);

  return response.data;
};

const getPromotionCategories = async () => {
  debugger
  const response = await axios.get(`${base_url}categories/getAllPromotionCat`);

  return response.data;
};

const getCategories = async () => {
  debugger
  const response = await axios.get(`${base_url}categories/getCategories`);

  return response.data;
};
// const createBlogCategory = async (bcat) => {
//   const response = await axios.post(`${base_url}blogcategory/`, bcat, config);

//   return response.data;
// };
// const updateBlogCategory = async (blogCat) => {
//   const response = await axios.put(
//     `${base_url}blogcategory/${blogCat.id}`,
//     { title: blogCat.blogCatData.title },
//     config
//   );

//   return response.data;
// };
// const getBlogCategory = async (id) => {
//   const response = await axios.get(`${base_url}blogcategory/${id}`, config);

//   return response.data;
// };

// const deleteBlogCategory = async (id) => {
//   const response = await axios.delete(`${base_url}blogcategory/${id}`, config);

//   return response.data;
// };
const globeBaseCategoryService = {
    getGlobeCategories,
    getPromotionCategories,
    getCategories,
//   createBlogCategory,
//   deleteBlogCategory,
//   getBlogCategory,
//   deleteBlogCategory,
//   updateBlogCategory,
};

export default globeBaseCategoryService;
