import { React, useEffect, useState } from "react";
import { useFormik } from "formik";
import UserControl from "../UserControl";
import { useNavigate, useLocation } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import Dropzone from "react-dropzone";
import { delImg, uploadImg, uploadPdf } from "../../redux/upload/uploadSlice";
import {
  createProducts,
  resetState,
  updateProduct,
} from "../../redux/product/productSlice";
import {
  getGlobeCategories,
  getPromotionCategories,
  getCategories,
} from "../../redux/globeBaseCategory/globeBaseCategorySlice";
import Accordion from "react-bootstrap/Accordion";
import classes from "./addproduct.module.css";
import { FaFilePdf } from "react-icons/fa";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

let schema = yup.object().shape({
  // entr_nme: yup.string().required("Title is Required"),
  // desc: yup.string().required("Description is Required"),
  // price: yup.number().required("Price is Required"),
  // brand: yup.string().required("Brand is Required"),
  // category: yup.string().required("Category is Required"),
  // tags: yup.string().required("Tag is Required"),
  // color: yup
  //   .array()
  //   .min(1, "Pick at least one color")
  //   .required("Color is Required"),
  // quantity: yup.number().required("Quantity is Required"),
});

const Addproduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [getProductId, setgetProductId] = useState(
    location.pathname.split("/")[3]
  );
  const [color, setColor] = useState([]);
  const [images, setImages] = useState([]);
  const [globeBase, setGlobeBase] = useState([]);

  console.log(color);
  useEffect(() => {
    dispatch(resetState());
    dispatch(getGlobeCategories());
    dispatch(getPromotionCategories());
    dispatch(getCategories());
  }, []);
  const imgState = useSelector((state) => state.upload.images);
  const pdfState = useSelector((state) => state.upload.pdf);
  const newProduct = useSelector((state) => state.product);
  const globeCateState = useSelector((state) => state.globeCat.globeCategories);
  const promotionCatState = useSelector(
    (state) => state.globeCat.promotionCategories
  );
  const categoriesState = useSelector((state) => state.globeCat.categories);
  const [optionsArray, setoptionsArray] = useState([
    { id: 0, optn_val: "", qty: 0, sbtrct_stck: false },
  ]);
  const { isSuccess, isError, isLoading, createdProduct } = newProduct;

  const selProduct = newProduct.products.filter(
    (x) => x._id === getProductId
  )[0];

  useEffect(() => {
    if (selProduct != undefined) {
      setoptionsArray(selProduct.options);
      img.push(selProduct.images);
      pdf.push(selProduct.pdfLink);
    }
  }, [getProductId]);

  const handleInputChange = (index, propertyName, value) => {
    // Step 3: Update the state based on input changes

    setoptionsArray((prevDataArray) => {
      const newDataArray = [...prevDataArray];
      newDataArray[index][propertyName] = value;
      return newDataArray;
    });
  };

  const handleCheckboxChange = (index) => {
    setoptionsArray((prevDataArray) => {
      const newDataArray = [...prevDataArray];
      newDataArray[index].sbtrct_stck = !newDataArray[index].sbtrct_stck;
      return newDataArray;
    });
  };

  useEffect(() => {
    if (isSuccess && createdProduct) {
      toast.success("Product Added Successfullly!");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);
  const globeCateOpt = [];
  globeCateState.forEach((i) => {
    globeCateOpt.push({
      label: i.title,
      value: i._id,
    });
  });

  const promotionCatOpt = [];
  promotionCatState.forEach((i) => {
    promotionCatOpt.push({
      label: i.title,
      value: i.title,
      id: i._id,
    });
  });
  const [selectPromotion, setSelectPromotion] = useState([]);
  const addPromotion = (event) => {
    setSelectPromotion(event);
  };

  const categoriesOpt = [];
  categoriesState.forEach((i) => {
    categoriesOpt.push({
      label: i.nme,
      value: i._id,
    });
  });
  const [selectCategories, setselectCategories] = useState([]);
  const addCategory = (event) => {
    setselectCategories(event);
  };
  const voltageCatOpt = [
    { label: "None", value: "1" },
    { label: "12v", value: "2" },
    { label: "24v", value: "3" },
    { label: "240v", value: "4" },
  ];
  const [startDate, setStartDate] = useState(new Date());
  const coloropt = [];

  const img = [];
  imgState.forEach((i) => {
    img.push({
      url: i,
    });
  });

  const pdf = [];
  pdfState.forEach((i) => {
    pdf.push({
      url: i,
    });
  });
  const AddRow = () => {
    setoptionsArray([
      ...optionsArray,
      { id: optionsArray.length, optn_val: "", qty: 0, sbtrct_stck: false },
    ]);
  };

  const removeRow = (index) => {
    const updatedArray = [...optionsArray];
    updatedArray.splice(index, 1);
    setoptionsArray(updatedArray);
  };

  useEffect(() => {
    formik.values.color = color ? color : " ";
    formik.values.images = img;
    formik.values.pdfLink = pdf;
    formik.values.dte_avlble = startDate;
    formik.values.options = optionsArray;
    formik.values.prmtnl_tgs = selectPromotion;
    formik.values.categories = selectCategories;
    // formik.value.globeBase = globeBase ? globeBase : " ";
  }, [
    color,
    img,
    pdf,
    startDate,
    optionsArray,
    selectPromotion,
    selectCategories,
  ]);

  const formik = useFormik({
    initialValues: {
      entr_nme: selProduct?.entr_nme || "",
      desc: selProduct?.desc || "",
      meta_tg: selProduct?.meta_tg || "",
      meta_desc: selProduct?.meta_desc || "",
      meta_key: selProduct?.meta_key || "",
      prdct_tg: selProduct?.prdct_tg || "",
      pdfLink: selProduct?.pdfLink || [],
      mdl: selProduct?.mdl || "",
      prc: selProduct?.prc || 0,
      sale_prc: selProduct?.sale_prc || 0,
      prdct_vrtn: selProduct?.prdct_vrtn || 0,
      wrnt_tab: selProduct?.wrnt_tab || "",
      bse: selProduct?.bse || "",
      shd_wdth: selProduct?.shd_wdth || "",
      shd_hght: selProduct?.shd_hght || "",
      hght_inc_cbl: selProduct?.hght_inc_cbl || "",
      hght_adj: selProduct?.hght_adj || false,
      flx_plg: selProduct?.flx_plg || "",
      shd_lngth: selProduct?.shd_lngth || "",
      prjct: selProduct?.prjct || "",
      shd_dmtr: selProduct?.shd_dmtr || "",
      suspnsn: selProduct?.suspnsn || "",
      ovrl_hght: selProduct?.ovrl_hght || "",
      bse_wdth: selProduct?.bse_wdth || "",
      bse_hgth: selProduct?.bse_hgth || "",
      bse_lngth: selProduct?.bse_lngth || "",
      bse_dmtr: selProduct?.bse_dmtr || "",
      fce_sze: selProduct?.fce_sze || "",
      ctout_sze: selProduct?.ctout_sze || "",
      dpth: selProduct?.dpth || "",
      images: selProduct?.images || [],
      qntity: selProduct?.qntity || 0,
      min_qntity: selProduct?.min_qntity || 0,
      out_of_stck_sts: selProduct?.out_of_stck_sts || false,
      rqur_shp: selProduct?.rqur_shp || false,
      prmtnl_tgs: selProduct?.prmtnl_tgs || [],
      seo_url: selProduct?.seo_url || "",
      dte_avlble: selProduct?.dte_avlble || new Date(),
      lnth: selProduct?.lnth || "",
      wdth: selProduct?.wdth || "",
      hght: selProduct?.hght || "",
      wght: selProduct?.wght || "",
      sts: selProduct?.sts || false,
      categories: selProduct?.categories || [],
      options: selProduct?.options || [],
      adjst_gimm_abl: selProduct?.adjst_gimm_abl || false,
      mdl_nme: selProduct?.mdl_nme || "",
      mdl_no: selProduct?.mdl_no || "",
      mtrls: selProduct?.mtrls || "",
      clr: selProduct?.clr || "",
      glbe_dmble: selProduct?.glbe_dmble || false,
      glb_inc: selProduct?.glb_inc || false,
      glb_bse: selProduct?.glb_bse || "",
      wt_age: selProduct?.wt_age || "",
      mx_glb_wt_age: selProduct?.mx_glb_wt_age || "",
      no_of_glbs: selProduct?.no_of_glbs || "",
      vltge: selProduct?.vltge || "",
      led_clr_tmp: selProduct?.led_clr_tmp || "",
      glbe_rplc: selProduct?.glbe_rplc || false,
      enrg_efcnt: selProduct?.enrg_efcnt || false,
      led_wtage: selProduct?.led_wtage || "",
      lght_clr_tmp: selProduct?.lght_clr_tmp || "",
      drvr_typ: selProduct?.drvr_typ || "",
      led_drvr_inc: selProduct?.led_drvr_inc || false,
      led_cmptbl: selProduct?.led_cmptbl || false,
      heat_glb: selProduct?.heat_glb || "",
      mnfctre: selProduct?.mnfctre || "",
      rtnl_dmtr: selProduct?.rtnl_dmtr || "",
      bem_angl: selProduct?.bem_angl || "",
      hetng_wtge: selProduct?.hetng_wtge || "",
      nois_lvl: selProduct?.nois_lvl || "",
      min_instl_dpth: selProduct?.min_instl_dpth || "",
      dctng_size: selProduct?.dctng_size || "",
      vrtl_tlt_angl: selProduct?.vrtl_tlt_angl || "",
      bld_mtrl: selProduct?.bld_mtrl || "",
      bld_ptch: selProduct?.bld_ptch || "",
      motr_wtg: selProduct?.motr_wtg || "",
      motr_typ: selProduct?.motr_typ || "",
      ctrlr_typ: selProduct?.ctrlr_typ || "",
      timr_func: selProduct?.timr_func || "",
      smr_wntr_rvrs: selProduct?.smr_wntr_rvrs || "",
      instl_loc: selProduct?.instl_loc || "",
      extrctn: selProduct?.extrctn || "",
      crnt: selProduct?.crnt || "",
      pwr: selProduct?.pwr || "",
      cnpy_dmtr: selProduct?.cnpy_dmtr || "",
      fan_hght: selProduct?.fan_hght || "",
      no_of_blds: selProduct?.no_of_blds || "",
      mtr_vltg: selProduct?.mtr_vltg || "",
      air_mvmnt: selProduct?.air_mvmnt || "",
      hng_sure_cnpt: selProduct?.hng_sure_cnpt || "",
      extnsn_rod_adptbl: selProduct?.extnsn_rod_adptbl || "",
      bal_berng_mtr: selProduct?.bal_berng_mtr || "",
      hrzntl_oslatn: selProduct?.hrzntl_oslatn || "",
      ip_rtng: selProduct?.ip_rtng || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getProductId !== undefined) {
        const data = { id: getProductId, productData: values };

        dispatch(updateProduct(data));
        //setgetProductId(null);
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
          dispatch(getGlobeCategories());
          dispatch(getPromotionCategories());
          dispatch(getCategories());
        }, 3000);
      } else {
        dispatch(createProducts(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
          dispatch(getGlobeCategories());
          dispatch(getPromotionCategories());
          dispatch(getCategories());
        }, 3000);
      }
    },
  });

  return (
    <div>
      <h3 className="mb-4 title">Add Product</h3>
      <div>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex gap-3 flex-column"
        >
          <UserControl
            type="text"
            label="Enter Product Title"
            name="entr_nme"
            className=" "
            onChng={formik.handleChange("entr_nme")}
            onBlr={formik.handleBlur("entr_nme")}
            val={formik.values.entr_nme}
          />
          <div className="error">
            {formik.touched.entr_nme && formik.errors.entr_nme}
          </div>
          <div className="">
            <ReactQuill
              theme="snow"
              name="desc"
              onChange={formik.handleChange("desc")}
              value={formik.values.desc}
            />
          </div>
          <div className="error">
            {formik.touched.desc && formik.errors.desc}
          </div>
          <UserControl
            type="text"
            label="Enter Meta Tag"
            name="meta_tg"
            className=" "
            onChng={formik.handleChange("meta_tg")}
            onBlr={formik.handleBlur("meta_tg")}
            val={formik.values.meta_tg}
          />
          <div className="error">
            {formik.touched.meta_tg && formik.errors.meta_tg}
          </div>
          <UserControl
            type="text"
            label="Enter Meta Description"
            name="meta_desc"
            className=" "
            onChng={formik.handleChange("meta_desc")}
            onBlr={formik.handleBlur("meta_desc")}
            val={formik.values.meta_desc}
          />
          <div className="error">
            {formik.touched.meta_desc && formik.errors.meta_desc}
          </div>
          <UserControl
            type="text"
            label="Enter Meta Key"
            name="meta_key"
            className=" "
            onChng={formik.handleChange("meta_key")}
            onBlr={formik.handleBlur("meta_key")}
            val={formik.values.meta_key}
          />
          <div className="error">
            {formik.touched.meta_key && formik.errors.meta_key}
          </div>

          <UserControl
            type="text"
            label="Enter Product Tag"
            name="prdct_tg"
            className=" "
            onChng={formik.handleChange("prdct_tg")}
            onBlr={formik.handleBlur("prdct_tg")}
            val={formik.values.prdct_tg}
          />
          <div className="error">
            {formik.touched.prdct_tg && formik.errors.prdct_tg}
          </div>

          <div className={classes.Row}>
            <div className={classes.Column}>
              <UserControl
                type="number"
                label="Enter Price"
                name="prc"
                className=" "
                onChng={formik.handleChange("prc")}
                onBlr={formik.handleBlur("prc")}
                val={formik.values.prc}
              />
              <div className="error">
                {formik.touched.prc && formik.errors.prc}
              </div>
            </div>
            <div className={classes.Column}>
              <UserControl
                type="number"
                label="Enter Sale Price"
                name="sale_prc"
                className=" "
                onChng={formik.handleChange("sale_prc")}
                onBlr={formik.handleBlur("sale_prc")}
                val={formik.values.sale_prc}
              />
              <div className="error">
                {formik.touched.sale_prc && formik.errors.sale_prc}
              </div>
            </div>
            <div className={classes.Column}>
              <UserControl
                type="text"
                label="Enter Model"
                name="mdl"
                className=" "
                onChng={formik.handleChange("mdl")}
                onBlr={formik.handleBlur("mdl")}
                val={formik.values.mdl}
              />
              <div className="error">
                {formik.touched.mdl && formik.errors.mdl}
              </div>
            </div>

            <div className={classes.Column}>
              <UserControl
                type="number"
                label="Enter Product Variations"
                name="prdct_vrtn"
                className=" "
                onChng={formik.handleChange("prdct_vrtn")}
                onBlr={formik.handleBlur("prdct_vrtn")}
                val={formik.values.prdct_vrtn}
              />
              <div className="error">
                {formik.touched.prdct_vrtn && formik.errors.prdct_vrtn}
              </div>
            </div>
          </div>

          <UserControl
            type="text"
            label="Warranty Tab"
            name="wrnt_tab"
            className=" "
            onChng={formik.handleChange("wrnt_tab")}
            onBlr={formik.handleBlur("wrnt_tab")}
            val={formik.values.wrnt_tab}
          />
          <div className="error">
            {formik.touched.wrnt_tab && formik.errors.wrnt_tab}
          </div>

          <Accordion>
            <Accordion.Item eventKey="0" className="mb-3">
              <Accordion.Header>Dimensions</Accordion.Header>
              <Accordion.Body>
                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Shade Width"
                      name="shd_wdth"
                      className=" "
                      onChng={formik.handleChange("shd_wdth")}
                      onBlr={formik.handleBlur("shd_wdth")}
                      val={formik.values.shd_wdth}
                    />
                    <div className="error">
                      {formik.touched.shd_wdth && formik.errors.shd_wdth}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Shade Height"
                      name="shd_hght"
                      className=" "
                      onChng={formik.handleChange("shd_hght")}
                      onBlr={formik.handleBlur("shd_hght")}
                      val={formik.values.shd_hght}
                    />
                    <div className="error">
                      {formik.touched.shd_hght && formik.errors.shd_hght}
                    </div>
                  </div>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Height Including Cable"
                      name="hght_inc_cbl"
                      className=" "
                      onChng={formik.handleChange("hght_inc_cbl")}
                      onBlr={formik.handleBlur("hght_inc_cbl")}
                      val={formik.values.hght_inc_cbl}
                    />
                    <div className="error">
                      {formik.touched.hght_inc_cbl &&
                        formik.errors.hght_inc_cbl}
                    </div>
                  </div>
                </div>
                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor="Height Adjustable"
                      >
                        Height Adjustable
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={formik.handleChange("hght_adj")}
                        checked={formik.values.hght_adj}
                      />
                    </div>
                    <div className="error">
                      {formik.touched.hght_adj && formik.errors.hght_adj}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Base Diameter"
                      name="bse"
                      className=" "
                      onChng={formik.handleChange("bse")}
                      onBlr={formik.handleBlur("bse")}
                      val={formik.values.bse}
                    />
                    <div className="error">
                      {formik.touched.bse && formik.errors.bse}
                    </div>
                  </div>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Suspension"
                      name="suspnsn"
                      className=" "
                      onChng={formik.handleChange("suspnsn")}
                      onBlr={formik.handleBlur("suspnsn")}
                      val={formik.values.suspnsn}
                    />
                    <div className="error">
                      {formik.touched.suspnsn && formik.errors.suspnsn}
                    </div>
                  </div>
                </div>
                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Cutout Size"
                      name="ctout_sze"
                      className=" "
                      onChng={formik.handleChange("ctout_sze")}
                      onBlr={formik.handleBlur("ctout_sze")}
                      val={formik.values.ctout_sze}
                    />
                    <div className="error">
                      {formik.touched.ctout_sze && formik.errors.ctout_sze}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Face Size"
                      name="fce_sze"
                      className=" "
                      onChng={formik.handleChange("fce_sze")}
                      onBlr={formik.handleBlur("fce_sze")}
                      val={formik.values.fce_sze}
                    />
                    <div className="error">
                      {formik.touched.fce_sze && formik.errors.fce_sze}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Depth"
                      name="dpth"
                      className=" "
                      onChng={formik.handleChange("dpth")}
                      onBlr={formik.handleBlur("dpth")}
                      val={formik.values.dpth}
                    />
                    <div className="error">
                      {formik.touched.dpth && formik.errors.dpth}
                    </div>
                  </div>
                </div>

                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Flex Plug"
                      name="flx_plg"
                      className=" "
                      onChng={formik.handleChange("flx_plg")}
                      onBlr={formik.handleBlur("flx_plg")}
                      val={formik.values.flx_plg}
                    />
                    <div className="error">
                      {formik.touched.flx_plg && formik.errors.flx_plg}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Projection"
                      name="prjct"
                      className=" "
                      onChng={formik.handleChange("prjct")}
                      onBlr={formik.handleBlur("prjct")}
                      val={formik.values.prjct}
                    />
                    <div className="error">
                      {formik.touched.prjct && formik.errors.prjct}
                    </div>
                  </div>
                  <div className={classes.Column}></div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className="mb-3">
              <Accordion.Header>Fan Specifications</Accordion.Header>
              <Accordion.Body>
                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Rotational Diameter"
                      name="rtnl_dmtr"
                      className=" "
                      onChng={formik.handleChange("rtnl_dmtr")}
                      onBlr={formik.handleBlur("rtnl_dmtr")}
                      val={formik.values.rtnl_dmtr}
                    />
                    <div className="error">
                      {formik.touched.rtnl_dmtr && formik.errors.rtnl_dmtr}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Blade Material"
                      name="bld_mtrl"
                      className=" "
                      onChng={formik.handleChange("bld_mtrl")}
                      onBlr={formik.handleBlur("bld_mtrl")}
                      val={formik.values.bld_mtrl}
                    />
                    <div className="error">
                      {formik.touched.bld_mtrl && formik.errors.bld_mtrl}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Blade Pitch"
                      name="bld_ptch"
                      className=" "
                      onChng={formik.handleChange("bld_ptch")}
                      onBlr={formik.handleBlur("bld_ptch")}
                      val={formik.values.bld_ptch}
                    />
                    <div className="error">
                      {formik.touched.bld_ptch && formik.errors.bld_ptch}
                    </div>
                  </div>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Motor Wattage"
                      name="motr_wtg"
                      className=" "
                      onChng={formik.handleChange("motr_wtg")}
                      onBlr={formik.handleBlur("motr_wtg")}
                      val={formik.values.motr_wtg}
                    />
                    <div className="error">
                      {formik.touched.motr_wtg && formik.errors.motr_wtg}
                    </div>
                  </div>
                </div>

                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Motor Type"
                      name="motr_typ"
                      className=" "
                      onChng={formik.handleChange("motr_typ")}
                      onBlr={formik.handleBlur("motr_typ")}
                      val={formik.values.motr_typ}
                    />
                    <div className="error">
                      {formik.touched.motr_typ && formik.errors.motr_typ}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Controller Type"
                      name="ctrlr_typ"
                      className=" "
                      onChng={formik.handleChange("ctrlr_typ")}
                      onBlr={formik.handleBlur("ctrlr_typ")}
                      val={formik.values.ctrlr_typ}
                    />
                    <div className="error">
                      {formik.touched.ctrlr_typ && formik.errors.ctrlr_typ}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Timer Function"
                      name="timr_func"
                      className=" "
                      onChng={formik.handleChange("timr_func")}
                      onBlr={formik.handleBlur("timr_func")}
                      val={formik.values.timr_func}
                    />
                    <div className="error">
                      {formik.touched.timr_func && formik.errors.timr_func}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Summer/Winter Return"
                      name="smr_wntr_rvrs"
                      className=" "
                      onChng={formik.handleChange("smr_wntr_rvrs")}
                      onBlr={formik.handleBlur("smr_wntr_rvrs")}
                      val={formik.values.smr_wntr_rvrs}
                    />
                    <div className="error">
                      {formik.touched.smr_wntr_rvrs &&
                        formik.errors.smr_wntr_rvrs}
                    </div>
                  </div>
                </div>

                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Installation Location"
                      name="instl_loc"
                      className=" "
                      onChng={formik.handleChange("instl_loc")}
                      onBlr={formik.handleBlur("instl_loc")}
                      val={formik.values.instl_loc}
                    />
                    <div className="error">
                      {formik.touched.instl_loc && formik.errors.instl_loc}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Current"
                      name="crnt"
                      className=" "
                      onChng={formik.handleChange("crnt")}
                      onBlr={formik.handleBlur("crnt")}
                      val={formik.values.crnt}
                    />
                    <div className="error">
                      {formik.touched.crnt && formik.errors.crnt}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Power"
                      name="pwr"
                      className=" "
                      onChng={formik.handleChange("pwr")}
                      onBlr={formik.handleBlur("pwr")}
                      val={formik.values.pwr}
                    />
                    <div className="error">
                      {formik.touched.pwr && formik.errors.pwr}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Canopy Diameter"
                      name="cnpy_dmtr"
                      className=" "
                      onChng={formik.handleChange("cnpy_dmtr")}
                      onBlr={formik.handleBlur("cnpy_dmtr")}
                      val={formik.values.cnpy_dmtr}
                    />
                    <div className="error">
                      {formik.touched.cnpy_dmtr && formik.errors.cnpy_dmtr}
                    </div>
                  </div>
                </div>

                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Fan Height"
                      name="fan_hght"
                      className=" "
                      onChng={formik.handleChange("fan_hght")}
                      onBlr={formik.handleBlur("fan_hght")}
                      val={formik.values.fan_hght}
                    />
                    <div className="error">
                      {formik.touched.fan_hght && formik.errors.fan_hght}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Number of Blades"
                      name="no_of_blds"
                      className=" "
                      onChng={formik.handleChange("no_of_blds")}
                      onBlr={formik.handleBlur("no_of_blds")}
                      val={formik.values.no_of_blds}
                    />
                    <div className="error">
                      {formik.touched.no_of_blds && formik.errors.no_of_blds}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Motor Voltage"
                      name="mtr_vltg"
                      className=" "
                      onChng={formik.handleChange("mtr_vltg")}
                      onBlr={formik.handleBlur("mtr_vltg")}
                      val={formik.values.mtr_vltg}
                    />
                    <div className="error">
                      {formik.touched.mtr_vltg && formik.errors.mtr_vltg}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="HangSure Canopy"
                      name="hng_sure_cnpt"
                      className=" "
                      onChng={formik.handleChange("hng_sure_cnpt")}
                      onBlr={formik.handleBlur("hng_sure_cnpt")}
                      val={formik.values.hng_sure_cnpt}
                    />
                    <div className="error">
                      {formik.touched.hng_sure_cnpt &&
                        formik.errors.hng_sure_cnpt}
                    </div>
                  </div>
                </div>

                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="IP Rating"
                      name="ip_rtng"
                      className=" "
                      onChng={formik.handleChange("ip_rtng")}
                      onBlr={formik.handleBlur("ip_rtng")}
                      val={formik.values.ip_rtng}
                    />
                    <div className="error">
                      {formik.touched.ip_rtng && formik.errors.ip_rtng}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Air Movement"
                      name="air_mvmnt"
                      className=" "
                      onChng={formik.handleChange("air_mvmnt")}
                      onBlr={formik.handleBlur("air_mvmnt")}
                      val={formik.values.air_mvmnt}
                    />
                    <div className="error">
                      {formik.touched.air_mvmnt && formik.errors.air_mvmnt}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Horizontal Oscillation"
                      name="hrzntl_oslatn"
                      className=" "
                      onChng={formik.handleChange("hrzntl_oslatn")}
                      onBlr={formik.handleBlur("hrzntl_oslatn")}
                      val={formik.values.hrzntl_oslatn}
                    />
                    <div className="error">
                      {formik.touched.hrzntl_oslatn &&
                        formik.errors.hrzntl_oslatn}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Beam Angle"
                      name="bem_angl"
                      className=" "
                      onChng={formik.handleChange("bem_angl")}
                      onBlr={formik.handleBlur("bem_angl")}
                      val={formik.values.bem_angl}
                    />
                    <div className="error">
                      {formik.touched.bem_angl && formik.errors.bem_angl}
                    </div>
                  </div>
                </div>

                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Heating Wattage"
                      name="hetng_wtge"
                      className=" "
                      onChng={formik.handleChange("hetng_wtge")}
                      onBlr={formik.handleBlur("hetng_wtge")}
                      val={formik.values.hetng_wtge}
                    />
                    <div className="error">
                      {formik.touched.hetng_wtge && formik.errors.hetng_wtge}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Noise Level"
                      name="nois_lvl"
                      className=" "
                      onChng={formik.handleChange("nois_lvl")}
                      onBlr={formik.handleBlur("nois_lvl")}
                      val={formik.values.nois_lvl}
                    />
                    <div className="error">
                      {formik.touched.nois_lvl && formik.errors.nois_lvl}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Minimum Install Depth"
                      name="min_instl_dpth"
                      className=" "
                      onChng={formik.handleChange("min_instl_dpth")}
                      onBlr={formik.handleBlur("min_instl_dpth")}
                      val={formik.values.min_instl_dpth}
                    />
                    <div className="error">
                      {formik.touched.min_instl_dpth &&
                        formik.errors.min_instl_dpth}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Ducting Size"
                      name="dctng_size"
                      className=" "
                      onChng={formik.handleChange("dctng_size")}
                      onBlr={formik.handleBlur("dctng_size")}
                      val={formik.values.dctng_size}
                    />
                    <div className="error">
                      {formik.touched.dctng_size && formik.errors.dctng_size}
                    </div>
                  </div>
                </div>

                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Vertical Tilt Angle"
                      name="vrtl_tlt_angl"
                      className=" "
                      onChng={formik.handleChange("vrtl_tlt_angl")}
                      onBlr={formik.handleBlur("vrtl_tlt_angl")}
                      val={formik.values.vrtl_tlt_angl}
                    />
                    <div className="error">
                      {formik.touched.vrtl_tlt_angl &&
                        formik.errors.vrtl_tlt_angl}
                    </div>
                  </div>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Extension Rode Adaptable"
                      name="extnsn_rod_adptbl"
                      className=" "
                      onChng={formik.handleChange("extnsn_rod_adptbl")}
                      onBlr={formik.handleBlur("extnsn_rod_adptbl")}
                      val={formik.values.extnsn_rod_adptbl}
                    />
                    <div className="error">
                      {formik.touched.extnsn_rod_adptbl &&
                        formik.errors.extnsn_rod_adptbl}
                    </div>
                  </div>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Ball Bearing Motor"
                      name="bal_berng_mtr"
                      className=" "
                      onChng={formik.handleChange("bal_berng_mtr")}
                      onBlr={formik.handleBlur("bal_berng_mtr")}
                      val={formik.values.bal_berng_mtr}
                    />
                    <div className="error">
                      {formik.touched.bal_berng_mtr &&
                        formik.errors.bal_berng_mtr}
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className="mb-3">
              <Accordion.Header>Product Specifications</Accordion.Header>
              <Accordion.Body>
                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Model Name"
                      name="mdl_nme"
                      className=" "
                      onChng={formik.handleChange("mdl_nme")}
                      onBlr={formik.handleBlur("mdl_nme")}
                      val={formik.values.mdl_nme}
                    />
                    <div className="error">
                      {/* {formik.touched.mdl_no && formik.errors.mdl_no} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Model No."
                      name="mdl_no"
                      className=" "
                      onChng={formik.handleChange("mdl_no")}
                      onBlr={formik.handleBlur("mdl_no")}
                      val={formik.values.mdl_no}
                    />
                    <div className="error">
                      {/* {formik.touched.mdl_no && formik.errors.mdl_no} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Materials"
                      name="mtrls"
                      className=" "
                      onChng={formik.handleChange("mtrls")}
                      onBlr={formik.handleBlur("mtrls")}
                      val={formik.values.mtrls}
                    />
                    <div className="error">
                      {/* {formik.touched.mtrls && formik.errors.mtrls} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Colors"
                      name="clr"
                      className=" "
                      onChng={formik.handleChange("clr")}
                      onBlr={formik.handleBlur("clr")}
                      val={formik.values.clr}
                    />
                    <div className="error">
                      {/* {formik.touched.clr && formik.errors.clr} */}
                    </div>
                  </div>
                </div>

                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor="Globe Dimmabel"
                      >
                        Globe Dimmabel
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={formik.handleChange("glbe_dmble")}
                        checked={formik.values.glbe_dmble}
                      />
                    </div>
                    <div className="error">
                      {/* {formik.touched.hght_adj && formik.errors.hght_adj} */}
                    </div>
                  </div>
                  <div className={classes.Column}>
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor="Globe Included"
                      >
                        Globe Included
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={formik.handleChange("glb_inc")}
                        checked={formik.values.glb_inc}
                      />
                    </div>
                    <div className="error">
                      {/* {formik.touched.hght_adj && formik.errors.hght_adj} */}
                    </div>
                  </div>
                  <div className={classes.Column}>
                    <Select
                      mode="single"
                      allowClear
                      className="w-100"
                      placeholder="Select Globe Base"
                      defaultValue={formik.values.glb_bse}
                      // onChange={(i) => handleGlobeBase(i)}
                      onChange={formik.handleChange("glb_bse")}
                      options={globeCateOpt}
                    />
                    <div className="error">
                      {/* {formik.touched.color && formik.errors.color} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Wattage"
                      name="wt_age"
                      className=" "
                      onChng={formik.handleChange("wt_age")}
                      onBlr={formik.handleBlur("wt_age")}
                      val={formik.values.wt_age}
                    />
                    <div className="error">
                      {/* {formik.touched.mtrls && formik.errors.mtrls} */}
                    </div>
                  </div>
                </div>
                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Max Globe Wattage"
                      name="mx_glb_wt_age"
                      className=" "
                      onChng={formik.handleChange("mx_glb_wt_age")}
                      onBlr={formik.handleBlur("mx_glb_wt_age")}
                      val={formik.values.mx_glb_wt_age}
                    />
                    <div className="error">
                      {/* {formik.touched.clr && formik.errors.clr} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="No. of Globes"
                      name="no_of_glbs"
                      className=" "
                      onChng={formik.handleChange("no_of_glbs")}
                      onBlr={formik.handleBlur("no_of_glbs")}
                      val={formik.values.no_of_glbs}
                    />
                    <div className="error">
                      {/* {formik.touched.no_of_glbs && formik.errors.no_of_glbs} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                  <label htmlFor="voltageSelect" className={classes.Label}>Voltage</label>
                    <Select
                      mode="single"
                      className="w-100"
                      placeholder="Select Voltage"
                      defaultValue={formik.values.vltge}
                      onChange={formik.handleChange("vltge")}
                      options={voltageCatOpt}
                    />
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Led Color Temperature"
                      name="led_clr_tmp"
                      className=" "
                      onChng={formik.handleChange("led_clr_tmp")}
                      onBlr={formik.handleBlur("led_clr_tmp")}
                      val={formik.values.led_clr_tmp}
                    />
                    <div className="error">
                      {/* {formik.touched.no_of_glbs && formik.errors.no_of_glbs} */}
                    </div>
                  </div>
                </div>
                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor="Globe Replacement"
                      >
                        Globe Replacement
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={formik.handleChange("glbe_rplc")}
                        checked={formik.values.glbe_rplc}
                      />
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor="Energy Efficient"
                      >
                        Energy Efficience
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={formik.handleChange("enrg_efcnt")}
                        checked={formik.values.enrg_efcnt}
                      />
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor="Adjustable/Gimmable"
                      >
                        Adjustable/Gimmable
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={formik.handleChange("adjst_gimm_abl")}
                        checked={formik.values.adjst_gimm_abl}
                      />
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Led Wattage"
                      name="led_wtage"
                      className=" "
                      onChng={formik.handleChange("led_wtage")}
                      onBlr={formik.handleBlur("led_wtage")}
                      val={formik.values.led_wtage}
                    />
                    <div className="error">
                      {/* {formik.touched.no_of_glbs && formik.errors.no_of_glbs} */}
                    </div>
                  </div>
                </div>
                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Light Color Temperature"
                      name="lght_clr_tmp"
                      className=" "
                      onChng={formik.handleChange("lght_clr_tmp")}
                      onBlr={formik.handleBlur("lght_clr_tmp")}
                      val={formik.values.lght_clr_tmp}
                    />
                    <div className="error">
                      {/* {formik.touched.no_of_glbs && formik.errors.no_of_glbs} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Driver Type"
                      name="drvr_typ"
                      className=" "
                      onChng={formik.handleChange("drvr_typ")}
                      onBlr={formik.handleBlur("drvr_typ")}
                      val={formik.values.drvr_typ}
                    />
                    <div className="error">
                      {/* {formik.touched.no_of_glbs && formik.errors.no_of_glbs} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor="Led Driver Included"
                      >
                        Led Driver Included
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={formik.handleChange("led_drvr_inc")}
                        checked={formik.values.led_drvr_inc}
                      />
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor="Led Compatible"
                      >
                        Led Compatible
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={formik.handleChange("led_cmptbl")}
                        checked={formik.values.led_cmptbl}
                      />
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Heat Globe"
                      name="heat_glb"
                      className=" "
                      onChng={formik.handleChange("heat_glb")}
                      onBlr={formik.handleBlur("heat_glb")}
                      val={formik.values.heat_glb}
                    />
                    <div className="error">
                      {/* {formik.touched.no_of_glbs && formik.errors.no_of_glbs} */}
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className="mb-3">
              <Accordion.Header>Product Specifications</Accordion.Header>
              <Accordion.Body>
                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="number"
                      label="Quantity"
                      name="qntity"
                      className=" "
                      onChng={formik.handleChange("qntity")}
                      onBlr={formik.handleBlur("qntity")}
                      val={formik.values.qntity}
                    />
                    <div className="error">
                      {/* {formik.touched.mdl_no && formik.errors.mdl_no} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="number"
                      label="Minimum Quanitity"
                      name="min_qntity"
                      className=" "
                      onChng={formik.handleChange("min_qntity")}
                      onBlr={formik.handleBlur("min_qntity")}
                      val={formik.values.min_qntity}
                    />
                    <div className="error">
                      {/* {formik.touched.mdl_no && formik.errors.mdl_no} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor="Out of Stock"
                      >
                        Out of Stock
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={formik.handleChange("out_of_stck_sts")}
                        checked={formik.values.out_of_stck_sts}
                      />
                    </div>
                    <div className="error">
                      {/* {formik.touched.hght_adj && formik.errors.hght_adj} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor="Requires Shipping"
                      >
                        Requires Shipping
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={formik.handleChange("rqur_shp")}
                        checked={formik.values.rqur_shp}
                      />
                    </div>
                    <div className="error">
                      {/* {formik.touched.hght_adj && formik.errors.hght_adj} */}
                    </div>
                  </div>
                </div>

                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <Select
                      mode="multiple"
                      allowClear
                      className="w-100"
                      placeholder="Select Promotional Tags"
                      defaultValue={formik.values.prmtnl_tgs}
                      // onChange={(i) => handleGlobeBase(i)}
                      onChange={addPromotion}
                      options={promotionCatOpt}
                      //options={promotionCatState.map(x => ({label : x.title,value : x.title }))}
                    />
                    <div className="error">
                      {/* {formik.touched.color && formik.errors.color} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Seo Url"
                      name="seo_url"
                      className=" "
                      onChng={formik.handleChange("seo_url")}
                      onBlr={formik.handleBlur("seo_url")}
                      val={formik.values.seo_url}
                    />
                    <div className="error">
                      {/* {formik.touched.mtrls && formik.errors.mtrls} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                    <label
                      className="form-check-label"
                      htmlFor="Available Date: "
                    >
                      Available Date:{" "}
                    </label>

                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    ></DatePicker>
                  </div>
                </div>
                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Length"
                      name="lnth"
                      className=" "
                      onChng={formik.handleChange("lnth")}
                      onBlr={formik.handleBlur("lnth")}
                      val={formik.values.lnth}
                    />
                    <div className="error">
                      {/* {formik.touched.clr && formik.errors.clr} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Width"
                      name="wdth"
                      className=" "
                      onChng={formik.handleChange("wdth")}
                      onBlr={formik.handleBlur("wdth")}
                      val={formik.values.wdth}
                    />
                    <div className="error">
                      {/* {formik.touched.clr && formik.errors.clr} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Height"
                      name="hght"
                      className=" "
                      onChng={formik.handleChange("hght")}
                      onBlr={formik.handleBlur("hght")}
                      val={formik.values.hght}
                    />
                    <div className="error">
                      {/* {formik.touched.clr && formik.errors.clr} */}
                    </div>
                  </div>
                </div>
                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Weight"
                      name="wght"
                      className=" "
                      onChng={formik.handleChange("wght")}
                      onBlr={formik.handleBlur("wght")}
                      val={formik.values.wght}
                    />
                    <div className="error">
                      {/* {formik.touched.clr && formik.errors.clr} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor="Status Enable"
                      >
                        Status Enable
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={formik.handleChange("sts")}
                        checked={formik.values.sts}
                      />
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" className="mb-3">
              <Accordion.Header>Links</Accordion.Header>
              <Accordion.Body>
                <div className={classes.Row}>
                  <div className={classes.Column}>
                    <UserControl
                      type="text"
                      label="Manufacturer"
                      name="mnfctre"
                      className=" "
                      onChng={formik.handleChange("mnfctre")}
                      onBlr={formik.handleBlur("mnfctre")}
                      val={formik.values.mnfctre}
                    />
                    <div className="error">
                      {/* {formik.touched.mdl_no && formik.errors.mdl_no} */}
                    </div>
                  </div>

                  <div className={classes.Column}>
                    <Select
                      mode="multiple"
                      allowClear
                      className="w-100"
                      placeholder="Select Categories"
                      defaultValue={formik.values.categories}
                      // onChange={(i) => handleGlobeBase(i)}
                      onChange={addCategory}
                      options={categoriesOpt}
                    />
                    <div className="error">
                      {/* {formik.touched.color && formik.errors.color} */}
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4" className="mb-3">
              <Accordion.Header>Options</Accordion.Header>
              <Accordion.Body>
                {optionsArray.map((data, index) => (
                  <div className={classes.Row}>
                    <div className={classes.Column}>
                      <UserControl
                        type="text"
                        label="Option Value"
                        name="optn_val"
                        className=" "
                        onChng={(e) =>
                          handleInputChange(index, "optn_val", e.target.value)
                        }
                        //onBlr={formik.handleBlur("mnfctre")}
                        val={data.optn_val}
                      />
                      <div className="error">
                        {/* {formik.touched.mdl_no && formik.errors.mdl_no} */}
                      </div>
                    </div>
                    <div className={classes.Column}>
                      <UserControl
                        type="number"
                        label="Quantity"
                        name="qty"
                        className=" "
                        onChng={(e) =>
                          handleInputChange(index, "qty", e.target.value)
                        }
                        //onBlr={formik.handleBlur("mnfctre")}
                        val={data.qty}
                      />
                      <div className="error">
                        {/* {formik.touched.mdl_no && formik.errors.mdl_no} */}
                      </div>
                    </div>
                    <div className={classes.Column}>
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="Subtract Stock"
                        >
                          Subtract Stock{" "}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={() => handleCheckboxChange(index)}
                          checked={data.sbtrct_stck}
                        />
                      </div>
                    </div>
                    <div className={classes.Row}>
                      <button onClick={() => removeRow(index)} type="button">
                        Remove Button
                      </button>
                    </div>
                  </div>
                ))}
                <div className={classes.Row}>
                  <button onClick={AddRow} type="button">
                    Add Row
                  </button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/* <UserControl
            type="number"
            label="Enter Product Price"
            name="price"
            onChng={formik.handleChange("price")}
            onBlr={formik.handleBlur("price")}
            val={formik.values.price}
          />
          <div className="error">
            {formik.touched.price && formik.errors.price}
          </div> */}
          {/* <select
            name="brand"
            onChange={formik.handleChange("brand")}
            onBlur={formik.handleBlur("brand")}
            value={formik.values.brand}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Select Brand</option>
            {brandState.map((i, j) => {
              return (
                <option key={j} value={i.title}>
                  {i.title}
                </option>
              );
            })}
          </select> */}
          {/* <div className="error">
            {formik.touched.brand && formik.errors.brand}
          </div>
          <select
            name="category"
            onChange={formik.handleChange("category")}
            onBlur={formik.handleBlur("category")}
            value={formik.values.category}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Select Category</option>
            {catState.map((i, j) => {
              return (
                <option key={j} value={i.title}>
                  {i.title}
                </option>
              );
            })}
          </select>
          <div className="error">
            {formik.touched.category && formik.errors.category}
          </div>
          <select
            name="tags"
            onChange={formik.handleChange("tags")}
            onBlur={formik.handleBlur("tags")}
            value={formik.values.tags}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="" disabled>
              Select Category
            </option>
            <option value="featured">Featured</option>
            <option value="popular">Popular</option>
            <option value="special">Special</option>
          </select>
          <div className="error">
            {formik.touched.tags && formik.errors.tags}
          </div>

          <Select
            mode="multiple"
            allowClear
            className="w-100"
            placeholder="Select colors"
            defaultValue={color}
            onChange={(i) => handleColors(i)}
            options={coloropt}
          />
          <div className="error">
            {formik.touched.color && formik.errors.color}
          </div>
          <UserControl
            type="number"
            label="Enter Product Quantity"
            name="quantity"
            onChng={formik.handleChange("quantity")}
            onBlr={formik.handleBlur("quantity")}
            val={formik.values.quantity}
          />
          <div className="error">
            {formik.touched.quantity && formik.errors.quantity}
          </div>*/}
          <div className="bg-white border-1 p-5 text-center">
            <Dropzone
              onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <h2>Upload Images</h2>
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="bg-white border-1 p-5 text-center">
            <Dropzone
              onDrop={(acceptedFiles) => {
                console.log("Accepted Files:", acceptedFiles);
                dispatch(uploadPdf(acceptedFiles));
              }}
              accept={["image/jpeg"]}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <h2>Upload Pdf</h2>
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="showimages d-flex flex-wrap gap-3">
            {pdf?.map((i, j) => {
              return (
                <div className=" position-relative" key={j}>
                  <button
                    type="button"
                    onClick={() => dispatch(delImg(i))}
                    className="btn-close position-absolute"
                    style={{ top: "0px", right: "5px" }}
                  ></button>
                  <a href={i.url} rel="noreferrer noopener" target="_blank">
                    <FaFilePdf size="80" />
                  </a>
                </div>
              );
            })}
          </div>
          <div className="showimages d-flex flex-wrap gap-3">
            {img?.map((i, j) => {
              return (
                <div className=" position-relative" key={j}>
                  <button
                    type="button"
                    onClick={() => dispatch(delImg(i.public_id))}
                    className="btn-close position-absolute"
                    style={{ top: "10px", right: "10px" }}
                  ></button>
                  <img src={i.url} alt="" width={200} height={200} />
                </div>
              );
            })}
          </div>
          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
          >
            {getProductId !== undefined ? "Edit" : "Add"} Product
          </button>
        </form>
      </div>
    </div>
  );
};

export default Addproduct;
