import axios from "axios";
import { config } from "../../utils/config";
import { base_url } from "../../utils/baseUrl";

const getProducts = async () => {
  debugger
  const response = await axios.get(`${base_url}product/getProducts`,config);

  return response.data;
};
const createProduct = async (product) => {
  debugger
  const response = await axios.post(`${base_url}product/addproduct`, product, config);

  return response.data;
};

const updateProduct = async (product) => {
  debugger
  const response = await axios.post(`${base_url}product/updateproduct`, product, config);

  return response.data;
};

const deleteProduct = async (productId) => {
  debugger
  const response = await axios.post(`${base_url}product/deleteproduct`, productId, config);

  return response.data;
};

const productService = {
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct
};

export default productService;
