import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import globeBaseCategoryService from "./globeBaseCategoryService";

export const getGlobeCategories = createAsyncThunk(
  "categories/getAllGlobeBaseCat",
  async (thunkAPI) => {
    try {
      return await globeBaseCategoryService.getGlobeCategories();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const getPromotionCategories = createAsyncThunk(
  "categories/getAllPromotionCat",
  async (thunkAPI) => {
    try {
      return await globeBaseCategoryService.getPromotionCategories();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (thunkAPI) => {
    try {
      return await globeBaseCategoryService.getCategories();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// export const createNewblogCat = createAsyncThunk(
//   "blogCategory/create-category",
//   async (catData, thunkAPI) => {
//     try {
//       return await bCategoryService.createBlogCategory(catData);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

// export const getABlogCat = createAsyncThunk(
//   "blogCategory/get-category",
//   async (id, thunkAPI) => {
//     try {
//       return await bCategoryService.getBlogCategory(id);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );
// export const updateABlogCat = createAsyncThunk(
//   "blogCategory/update-category",
//   async (blogCat, thunkAPI) => {
//     try {
//       return await bCategoryService.updateBlogCategory(blogCat);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

// export const deleteABlogCat = createAsyncThunk(
//   "blogCategory/delete-category",
//   async (id, thunkAPI) => {
//     try {
//       return await bCategoryService.deleteBlogCategory(id);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );
export const resetState = createAction("Reset_all");
const initialState = {
  globeCategories: [],
  promotionCategories: [],
  categories: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const globeBaseCategorySlice = createSlice({
  name: "globeCategories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGlobeCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGlobeCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.globeCategories = action.payload;
      })
      .addCase(getGlobeCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getPromotionCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPromotionCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.promotionCategories = action.payload;
      })
      .addCase(getPromotionCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.categories = action.payload;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
    //   .addCase(createNewblogCat.pending, (state) => {
    //     state.isLoading = true;
    //   })
    //   .addCase(createNewblogCat.fulfilled, (state, action) => {
    //     state.isLoading = false;
    //     state.isError = false;
    //     state.isSuccess = true;
    //     state.createBlogCategory = action.payload;
    //   })
    //   .addCase(createNewblogCat.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.isError = true;
    //     state.isSuccess = false;
    //     state.message = action.error;
    //   })
    //   .addCase(getABlogCat.pending, (state) => {
    //     state.isLoading = true;
    //   })
    //   .addCase(getABlogCat.fulfilled, (state, action) => {
    //     state.isLoading = false;
    //     state.isError = false;
    //     state.isSuccess = true;
    //     state.blogCatName = action.payload.title;
    //   })
    //   .addCase(getABlogCat.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.isError = true;
    //     state.isSuccess = false;
    //     state.message = action.error;
    //   })
    //   .addCase(updateABlogCat.pending, (state) => {
    //     state.isLoading = true;
    //   })
    //   .addCase(updateABlogCat.fulfilled, (state, action) => {
    //     state.isLoading = false;
    //     state.isError = false;
    //     state.isSuccess = true;
    //     state.updatedBlogCategory = action.payload;
    //   })
    //   .addCase(updateABlogCat.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.isError = true;
    //     state.isSuccess = false;
    //     state.message = action.error;
    //   })
    //   .addCase(deleteABlogCat.pending, (state) => {
    //     state.isLoading = true;
    //   })
    //   .addCase(deleteABlogCat.fulfilled, (state, action) => {
    //     state.isLoading = false;
    //     state.isError = false;
    //     state.isSuccess = true;
    //     state.deletedBlogCategory = action.payload;
    //   })
    //   .addCase(deleteABlogCat.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.isError = true;
    //     state.isSuccess = false;
    //     state.message = action.error;
    //   })
      .addCase(resetState, () => initialState);
  },
});
export default globeBaseCategorySlice.reducer;
