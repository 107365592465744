import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './components/login/Login';
import SideBar from './components/sideBar/SideBar';
import Addproduct from './components/product/AddProduct';
import Productlist from './components/product/ProductList';
import { useDispatch, useSelector } from "react-redux";

function App() {

  const user = useSelector((state) => state.auth.user);
  const isAdmin = user ? user.others.isAdmin : false
  console.log(user)
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />  
        { isAdmin &&
        <Route path="/admin" element={<SideBar />}>
          <Route path="product" element={<Addproduct />} />
          <Route path="product/:id" element={<Addproduct />} />
          <Route path="list-product" element={<Productlist />} />
        </Route>
}
      </Routes>
    </Router>
  );
}

export default App;
